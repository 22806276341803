var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "carousel-container" },
    [
      _vm._m(0),
      _c(
        "el-carousel",
        {
          staticClass: "bg-pic",
          attrs: { interval: 5000, arrow: "never", autoplay: true },
        },
        _vm._l(_vm.picList, function (item, index) {
          return _c("el-carousel-item", { key: index }, [
            _c("div", {
              staticClass: "pic",
              style: { background: `url(${item})` },
            }),
          ])
        }),
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-enfry" }, [
      _c("div", [_vm._v("河洛 更省时 省心 省钱")]),
      _c("div", { staticClass: "title-enfry__tip" }, [
        _vm._v("Beyond  your  expectation"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }