<!--
 * @Author: Fenngding
 * @Date: 2019-10-21 17:24:22
 * @LastEditors: Fengding
 * @LastEditTime: 2019-10-21 17:48:50
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="carousel-container">
     <div class="title-enfry">
       <div>河洛 更省时 省心 省钱</div>
       <div class="title-enfry__tip">Beyond&nbsp;&nbsp;your&nbsp;&nbsp;expectation</div>
      </div>
    <el-carousel
      :interval="5000"
      arrow="never"
      :autoplay="true"
      class="bg-pic"
    >
      <el-carousel-item
        v-for="(item,index) in picList"
        :key="index"
      >
        <div
          class="pic"
          :style="{background:`url(${item})`}"
        ></div>
      </el-carousel-item>
    </el-carousel>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Carousel",
  components: {},
  props: {
    picList: {
      type: Array,
      default: () => [
        "https://images.softfuxi.com/static/user_flight-bg-1.jpg",
        "https://images.softfuxi.com/static/user_flight-bg-2.jpg",
        "https://images.softfuxi.com/static/user_flight-bg-3.jpg"
        // "http://cdn.enfry.com/hotel_banner114409.jpg",
        // "http://cdn.enfry.com/hotel_banner114409.jpg",
        // "http://cdn.enfry.com/hotel_banner114409.jpg"
      ]
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.carousel-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  .title-enfry {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(30% - 145px);
    z-index: 3;
    font-size: 36px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 40px;
    font-family: RTWSYueGoTrial;
    .title-enfry__tip {
      font-size: 22px;
      font-family: Arial;
      font-weight: 400;
      color: rgba(254,255,255,1);
      text-align: right;
    }
  }
  .bg-pic {
    height: 100%;
    width: 100%;
    overflow: hidden;
    & /deep/ .el-carousel__container {
      height: 100%;
    }
    .bg {
      height: 100%;
      width: 100%;
    }
    .pic {
      height: 100%;
      width: 100%;
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position: center !important;
    }
  }
  /deep/ {
    .el-carousel {
      .el-carousel__indicator--horizontal {
        padding: 12px 8px;
      }
      .el-carousel__indicators {
        .el-carousel__indicator {
          &.is-active {
            .el-carousel__button {
              transform: scale(1.5);
              background-color: #3e90fe;
            }
          }
        }
        .el-carousel__button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #FFFFFF;
          opacity: 1;
        }
      }
    }
  }
}
</style>
